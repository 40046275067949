.App {
  text-align: center;
}

@font-face {
  font-family: 'Linden Hill';
  src: url('./font/LindenHill-Regular.ttf') format('truetype');
}

body {
  font-family: 'Linden Hill', serif; /* Apply the font to the body or any other desired element */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.grayscale {
  filter: grayscale(100%);
}

.photo-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center items horizontally */
  margin: auto; /* Center the container itself horizontally */
  overflow: auto;
}

.photo-wrapper {
  margin: 5px;
}

.photo {
  width: 250px; /* Adjust size as needed */
  height: auto;
  transition: filter 0.3s ease;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  display: flex;
  min-height: 100vh;
  min-width: 100vw;
  position: relative;
}

.sidebar {
  width: 400px;
  background-color: #000;
  padding: 20px;
  position: relative;
}

.sidebar::after {
  content: "";
  position: absolute;
  top: 20px; /* Adjust the top position to match the top padding */
  right: 0;
  bottom: 20px; /* Adjust the bottom position to match the bottom padding */
  width: 1px;
  background-color: #555;
}

.logo:hover {
  cursor: pointer;
}

.logo-img {
  width: 50%;
  margin: auto;
}

.menu-item {
  cursor: pointer;
  margin-bottom: 15px;
  border-bottom: 1px solid #555; /* Add separator between menu items */
  position: relative;
  left: 10%; /* Position it at the start of the menu item */
  width: 80%; /* Make it span 80% of the width of the menu item */
}

.menu-item.with-line::before {
  content: ""; /* Add content to the pseudo-element */
  position: absolute; /* Position the pseudo-element */
  top: -15px; /* Adjust the position to appear above the menu item */
  left: 0; /* Position it at the start of the menu item */
  width: 100%; /* Make it span the full width of the menu item */
  border-top: 1px solid #555; /* Style the line */
}

.menu-text,
.menu-code {
  margin: 15px; /* Add margin around the text */
  font-size: 1.2em;
  color: white;
  text-align: center;
}

.menu-code {
  font-size: 0.8em;
}

.menu-item:last-child {
  margin-bottom: 0;
}

.social-icons {
  position: absolute;
  bottom: 0;
  left: 50%; 
  transform: translateX(-50%); 
  display: flex;
  justify-content: center; 
  align-items: center; 
}

.social-icon {
  cursor: pointer;
  width: 35px;
  margin: 25px 20px;
}

.content {
  flex: 1;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-text {
  font-size: 1.2em;
  color: white;
  text-align: center;
  white-space: pre-line;
}

/* Hamburger menu styles */
.hamburger-menu {
  display: none; /* Hide by default on wider screens */
  cursor: pointer;
  padding: 10px;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #fff; /* Color of the hamburger bars */
  margin: 5px 0;
}

.main-nav {
  display: none;
}

.burger {
	display: none;
}

@media only screen and (max-width: 768px) {
  .sidebar {
    position: fixed; /* Position the navbar fixed at the top */
    top: 0; /* Position it at the top */
    left: 0;
    width: 100%;
    z-index: 9999; /* Ensure navbar is above content */
  }


  .content {
    padding-top: calc(20px); /* Adjust content padding to account for navbar height */
  }

  .social-icons-mobile {
    position: absolute;
    bottom: -200px;
    left: 50%; 
    transform: translateX(-175%); 
    display: flex;
    justify-content: center; 
    align-items: center; 
  }
  
  .social-icon {
    cursor: pointer;
    width: 35px;
    margin: 25px 12.5px;
    transition: all .375s;
  }

  .social-icons {
    display: none;
  }
  
  .content-text {
    font-size: 1.2em;
    color: white;
    text-align: center;
    white-space: pre-line;
    margin: auto;
  }

  .photo-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    overflow-x: auto;
    overflow-y: auto;
    max-height: calc(100vh - 120px); /* Adjust maximum height accordingly */
    padding-top: 75px; /* Adjust top padding to push the photos down */
  }
  
  

  .logo-img {
    width: 30%;
    margin: auto;
  }
  
  .grayscale {
    filter: grayscale(0%);
  }
  
  .photo {
    width: 150px; /* Adjust size as needed */
    height: auto;
    transition: filter 0.3s ease;
  }

  .main-nav {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    text-align: center;
    background: #000;
    opacity: 0;
    z-index: -1;
    visibility: hidden;
    transition: all .375s;
}

.main-nav.is-open {
    opacity: 1;
    z-index: 100;
    visibility: visible;
}

/* Yellow band effect */
.main-nav::before {
	 content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: -15px;
    background: #0f0f0f;
    transform-origin: 0 0;
    transform: skew(-14deg) translateX(-120%);
    transition: all .275s .1s;
}

.main-nav.is-open::before {
    transform: skew(-14deg) translateX(0);
}

/* Skewing effect on menu links */
.main-nav ul {
    display: inline-flex;
    flex-direction: column;
    height: 93%; /* Should be 100%, but we have a notice message :D */
    align-items: flex-end;
    justify-content: center;
    transform: translateX(-18%) skew(-16deg);
}

.main-nav li {
    display: block;
    margin: .5rem 0;
    text-align: right;
    transform: skew(16deg);
}

/* Apparition effect on links */
.main-nav a {
    opacity: 0;
    transform: translateY(-10px);
}

.main-nav.is-open a {
    opacity: 1;
    transform: translateY(0);
}
.main-nav li:nth-child(1) a {
	transition: all 275ms 175ms
}
.main-nav li:nth-child(2) a {
	transition: all 275ms 225ms
}
.main-nav li:nth-child(3) a {
	transition: all 275ms 275ms
}
.main-nav li:nth-child(4) a {
	transition: all 275ms 325ms
}
.main-nav li:nth-child(5) a {
	transition: all 275ms 375ms
}


/* Decoration */
.main-nav ul,
.main-nav li {
	list-style: none;
	padding: 0;
}
.main-nav a {
	display: block;
	padding: 12px 0;
	color: #fff;
	font-size: 1.4em;
	text-decoration: none;
	font-weight: bold;
}

/* Burger Style: @see: https://codepen.io/CreativeJuiz/full/oMZNXy */
.open-main-nav {
	position: absolute;
	top: 15px;
	padding-top: 20px;
	right: 15px;
	z-index: 1000;
	background: none;
	border: 0;
	cursor: pointer;
}
.open-main-nav:focus {
	outline: none;
}
.burger {
	position: relative;
	display: block;
	width: 28px;
	height: 4px;
	margin: 0 auto;
	background: #fff;
	transform: skew(5deg);
	transition: all .275s;
}

.burger:after,
.burger:before {
	content: '';
	display: block;
	height: 100%;
	background: #fff;
	transition: all .275s;
}

.burger:after {
	transform: translateY(-12px) translateX(-2px) skew(-20deg);
}

.burger:before {
	transform: translateY(-16px) skew(-10deg);
}

/* Toggle State part */
.is-open .burger {
	transform: skew(5deg) translateY(-8px) rotate(-45deg);
}

.is-open .burger:before {
	transform: translateY(0px) skew(-10deg) rotate(75deg);
}

.is-open .burger:after {
	transform: translateY(-12px) translateX(10px) skew(-20deg);
    opacity: 0;
}

/* MENU Text part */

.burger-text {
	display: block;
	font-size: .675rem;
	letter-spacing: .05em;
	margin-top: .5em;
	text-transform: uppercase;
	font-weight: 500;
	text-align: center;
	color: #fff;
}

.device {
	position: relative;
	width: 345px;
	height: 600px;
	background: #FFF;
	border: 1px solid #EEE;
	border-radius: 3px;
	box-shadow: 0 0 0 10px rgba(0,0,0,.1);
}

.container {
	position: absolute;
	top: 0; right: 0;
	bottom: 0; left: 0;
	overflow: hidden;
	background: linear-gradient(to bottom, #eee, #ddd);
}

html {
	height: 100%;
}
body {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

/* Slow motion button */
[id="slowmo"] {
	position: absolute;
	top: 20px;
	right: 20px;
	padding: 10px;
	border: 0;
	font-size: 1rem;
	background: #FEDC2A;
	color: #5A3B5D;
	font-weight: bold;
	cursor: pointer;
	transition: all .275s;
}

[id="slowmo"] span {
	display: block;
	font-weight: normal;
}

[id="slowmo"]:hover,
[id="slowmo"]:focus {
	background: #5A3B5D;
	color: #FEDC2A;
}

[id="slowmo"].is-slowmo span:after {
	content: 'Activated';
	display: block;
	font-weight: bold;
}

/* When slowmotion is activated */

.is-slowmo + .device .open-main-nav .burger,
.is-slowmo + .device .open-main-nav .burger:before,
.is-slowmo + .device .open-main-nav .burger:after,
.is-slowmo + .device .main-nav,
.is-slowmo + .device .main-nav::before,
.is-slowmo + .device .main-nav a {
	transition-duration: 3s;
}
.is-slowmo + .device .main-nav li:nth-child(1) a {
	transition-delay: 1750ms
}
.is-slowmo + .device .main-nav li:nth-child(2) a {
	transition-delay: 2250ms
}
.is-slowmo + .device .main-nav li:nth-child(3) a {
	transition-delay: 2750ms
}
.is-slowmo + .device .main-nav li:nth-child(4) a {
	transition-delay: 3250ms
}
.is-slowmo + .device .main-nav li:nth-child(5) a {
	transition-delay: 3750ms
}

/* Notice */
.notice {
	position: absolute;
	bottom: -15px;
	left: 0; right: 0;
	padding: 20px;
	background: #F2F2F2;
	color: #5A3B5D;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.5;
	z-index: 100;
	text-align: center;
}
.notice strong {
	font-weight: 700;
}
.notice a {
	padding: 2px 3px;
	background: #FEDC2A;
	text-decoration: none;
}

  /* Show sidebar when hamburger menu is active */
  .sidebar.active {
    display: block;
  }
  
  .menu {
    display: none;
  }
}
